<template>
    <div class="col-12">
        <hr>
        <b-nav card-header tabs>
            <b-nav-item :active="subStatus == 'processing' ? true : false"
                :to="{
                    name: `pemeriksaanPemeriksaanProcessingProcessing`, 
                    params: {subStatus: 'processing'}, 
                    query: {
                        'jenis-pemeriksaan' : jenisPemeriksaanFilter,
                        'tanggal-mulai' : tMulai,
                        'tanggal-akhir' : tAkhir
                    }
                }"
            >
                Processing&nbsp;
                <b-badge variant="light-success">
                {{cProcessing}}
                </b-badge>
            </b-nav-item>
            <b-nav-item :active="subStatus == 'susul' ? true : false"
                :to="{
                    name: 'pemeriksaanPemeriksaanProcessingSusul', 
                    params: {subStatus: 'susul'}, 
                    query: {
                        'jenis-pemeriksaan' : jenisPemeriksaanFilter,
                        'tanggal-mulai' : tMulai,
                        'tanggal-akhir' : tAkhir
                    }
                }"
            >
                Susul&nbsp;
                <b-badge variant="light-info">
                {{cSusul}}
                </b-badge>
            </b-nav-item>
            <b-nav-item :active="subStatus == 'pld' ? true : false"
                :to="{
                    name: 'pemeriksaanPemeriksaanProcessingPld', 
                    params: {subStatus: 'pld'}, 
                    query: {
                        'jenis-pemeriksaan' : jenisPemeriksaanFilter,
                        'tanggal-mulai' : tMulai,
                        'tanggal-akhir' : tAkhir
                    }
                }"
            >
                PLD&nbsp;
                <b-badge variant="light-secondary">
                {{cPld}}
                </b-badge>
            </b-nav-item>
            <b-nav-item :active="subStatus == 'konfirmasi' ? true : false"
                :to="{
                    name: 'pemeriksaanPemeriksaanProcessingKonfirmasi', 
                    params: {subStatus: 'konfirmasi'}, 
                    query: {
                        'jenis-pemeriksaan' : jenisPemeriksaanFilter,
                        'tanggal-mulai' : tMulai,
                        'tanggal-akhir' : tAkhir
                    }
                }"
            >
                Konfirmasi&nbsp;
                <b-badge variant="light-warning">
                {{cKonfirmasi}}
                </b-badge>
            </b-nav-item>
        </b-nav>
    </div>
</template>

<script>
import {
    BNav, BNavItem, BBadge
} from 'bootstrap-vue'
import {onUpdated} from '@vue/composition-api'

export default {
    setup(props, context){
        context.emit('subStatus', props.subStatus)
        onUpdated( () => {
            context.emit('subStatus', props.subStatus)
        })
    },
    props: {
        subStatus: String,
        jenisPemeriksaanFilter: String,
        tMulai: String,
        tAkhir: String,
        cProcessing: Number,
        cSusul: Number,
        cPld: Number,
        cKonfirmasi: Number,
    },
    components: {
        BNav, BNavItem, BBadge
    },
}
</script>